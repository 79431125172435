var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          { key: 'consecutive', label: 'Invoice', helper: { id: 'invoice.consecutive', default: 0 } }, // TODO: default value for not generated invoices
          _vm.helpers.monthName,
          { key: 'month_id', _classes: 'hide' },
          { key: 'imputation_name', label: 'Imputation', helper: { id: 'imputation.name' } },
          _vm.helpers.building,
          _vm.getHelperObject('building_type', 'building.building_type_id'),
          _vm.getHelperObject('property_unit', 'building.property_unit_id'),
          _vm.helpers.grouper,
          { key: 'grouper_type', helper: { id: 'grouper.building_type_id', parser: function (id) { return this$1.parsers.readHelper(id, 'building_types', 'name'); } } },
          { key: 'grouper_unit', helper: { id: 'grouper.property_unit_id', parser: function (id) { return this$1.parsers.readHelper(id, 'property_units', 'name'); } } },

          _vm.helpers.ownerName,
          _vm.helpers.currencyValue,
          { key: 'description', label: 'Note' },
          { key: 'input_by', helper: { id: '_user.email' } },
          _vm.helpers.createdAt,
          { key: 'origin_month', helper: { id: 'origin_month_id', parser: function (month_id) { return this$1.parsers.periodName(month_id); } } },
          { key: 'invoice_detail_type_id', label: ' ' } // TODO: Use type_name on Excel file
        ],"config":{
          name: 'invoice',
          url: 'admin/invoices/details',
          route: '/admin/invoicing/details',
          //params: { _whits: 'early_payment_discount', _lists: 'months,contact_types,building_types,property_units' },
          filters: [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'imputation_name', label: 'Imputation', config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'building_type', config: { select_all: true } }, { key: 'property_unit', config: { select_all: true } }, { key: 'grouper_code', use: 'grouper.identifier' }, 'owner_name'],
          noCreate: true,
          noOptions: 'hide'
        },"sorterValue":{ column: 'consecutive', asc: false }},on:{"dataChanged":_vm.onDataChanged,"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }