<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            { key: 'consecutive', label: 'Invoice', helper: { id: 'invoice.consecutive', default: 0 } }, // TODO: default value for not generated invoices
            helpers.monthName,
            { key: 'month_id', _classes: 'hide' },
            { key: 'imputation_name', label: 'Imputation', helper: { id: 'imputation.name' } },
            helpers.building,
            getHelperObject('building_type', 'building.building_type_id'),
            getHelperObject('property_unit', 'building.property_unit_id'),
            helpers.grouper,
            { key: 'grouper_type', helper: { id: 'grouper.building_type_id', parser: id => this.parsers.readHelper(id, 'building_types', 'name') } },
            { key: 'grouper_unit', helper: { id: 'grouper.property_unit_id', parser: id => this.parsers.readHelper(id, 'property_units', 'name') } },

            helpers.ownerName,
            helpers.currencyValue,
            { key: 'description', label: 'Note' },
            { key: 'input_by', helper: { id: '_user.email' } },
            helpers.createdAt,
            { key: 'origin_month', helper: { id: 'origin_month_id', parser: month_id => this.parsers.periodName(month_id) } },
            { key: 'invoice_detail_type_id', label: ' ' } // TODO: Use type_name on Excel file
          ]"
          :config="{
            name: 'invoice',
            url: 'admin/invoices/details',
            route: '/admin/invoicing/details',
            //params: { _whits: 'early_payment_discount', _lists: 'months,contact_types,building_types,property_units' },
            filters: [{ key: 'month_id', multiple: true, default: 'response.data.month_ids', forced: true, list: 'months', callback: this.onGetData, config: { select_all: true } }, { key: 'imputation_name', label: 'Imputation', config: { select_all: true } }, { key: 'building_code', use: 'building.identifier' }, { key: 'building_type', config: { select_all: true } }, { key: 'property_unit', config: { select_all: true } }, { key: 'grouper_code', use: 'grouper.identifier' }, 'owner_name'],
            noCreate: true,
            noOptions: 'hide'
          }"
          :sorterValue="{ column: 'consecutive', asc: false }"
          @dataChanged="onDataChanged"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Details',
  mixins: [crudMixin],
  data() {
    return {
      month: {},
      discount: false
    }
  },
  methods: {
    onGetData(month_ids) {
      // if (month_id) {
      // this.month = { month_id, _period_name: this.getPeriodName(month_id) }
      this.$refs.crud_table.getData(false, { month_ids }, true)
      // }
    },
    onDataChanged() {
      //this.discount = !!this.$refs.crud_table.response.data.early_payment_discount?.status_id
    }
  }
}
</script>
